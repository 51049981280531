import { Injectable, OnInit } from '@angular/core';
import { HttpClientService } from '../httpClient/httpClient.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UniversityAPI } from '../../constants/api-constant/university-api.const';
import { PaginationType } from '../../interfaces/pagination/pagination.interface';
import { LocalStorageService } from 'src/app/core/services/localStorage/localStorage.service';
import { StorageConst } from '../../constants/storage.const';

@Injectable({
    providedIn: 'root',
})
export class UniversityService {
    resetListner = new BehaviorSubject<boolean>(false);

    resetListner$ = this.resetListner.asObservable();
    private setUniversity$ = new BehaviorSubject<any>(null);
    isAllApplication$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    constructor(
        private _httpClientService: HttpClientService,
        private _localStorageService: LocalStorageService
    ) {}

    getUrmUniversity(
        pagination: PaginationType,
        tenant_id?: any,
        filters?: any,
        search?: string
    ): Observable<any> {
        let query = '?';
        if (tenant_id) {
            query += `tenant=${tenant_id}&`;
        }
        // if (university_country) {
        //   query += `universityCountry=${university_country}&`;
        // }
        if (search) {
            query += `search=${search}&`;
        }
        if (filters) {
            query += filters;
        }
        query += `skip=${pagination.skip}&limit=${pagination.limit}`;

        return this._httpClientService.get(
            `${UniversityAPI.getUrmUniversity}${query}`
        );
    }

    getUniversity(
        pagination: PaginationType,
        tenant_id?: any,
        university_country?: string,
        search?: string,
        intake?: any,
        country?: any
    ): Observable<any> {
        let query = '?';
        if (tenant_id) {
            query += `tenant=${tenant_id}&`;
        }
        if (university_country) {
            query += `universityCountry=${university_country}&`;
        }
        if (search) {
            query += `search=${search}&`;
        }
        if (intake) {
            query += `intake=${intake}&`;
        }
        if (country) {
            query += `country=${country}&`;
        }

        query += `skip=${pagination.skip}&limit=${pagination.limit}`;

        return this._httpClientService.get(
            `${UniversityAPI.getUniversity}${query}`
        );
    }

    getUniversityProcess(
        pagination: PaginationType,
        filters?: any
    ): Observable<any> {
        let query = '?';

        if (filters) {
            query += filters;
        }

        query += `skip=${pagination.skip}&limit=${pagination.limit}`;
        const url = `${UniversityAPI.getUniversityProcess}${query}`;
        return this._httpClientService.get(url);
    }

    universityAdmin(id: any): Observable<any> {
        return this._httpClientService.get(
            `${UniversityAPI.universityAdmin}=${id}`
        );
    }

    getIndianBoards(): Observable<any> {
        return this._httpClientService.get(
            `${UniversityAPI.getBoardName}?skip=0&limit=3000`
        );
    }

    getUniversityWitheligibility(
        pagination: PaginationType,
        tenant_id: any,
        university_country: any,
        country: string,
        filters: any
    ): Observable<any> {
        let query = '?';
        console.log(tenant_id);

        if (tenant_id) {
            query += `tenant=${tenant_id}&`;
        }
        if (university_country) {
            query += `universityCountry=${university_country}&`;
        }
        if (country) {
            query += `country=${country}&`;
        }
        if (filters) {
            query += filters;
        }
        query += `skip=${pagination.skip}&limit=${pagination.limit}`;
        return this._httpClientService.get(
            `${UniversityAPI.getUniversityWithEligibility}${query}`
        );
    }

    checkElgibilityUniversity(
        courseId: string,
        uniCountry: any,
        country: any,
        degreeId: any,
        gradeScore: any,
        englishProficiency: any,
        englishProficiencyTotal: any,
        englishOtherDetailsObj?: any
    ): Observable<any> {
        return this._httpClientService.get(
            `${UniversityAPI.getUniversityWithEligibility}?tenant=1${
                courseId != '' ? `&courseId=${courseId}` : ''
            }&universityCountry=${uniCountry}&country=${country}&acadamicId=${degreeId}&acadamicTotal=${gradeScore}&englishProficiency=${englishProficiency}${
                !englishOtherDetailsObj
                    ? `&englishProficiencyTotal=${englishProficiencyTotal}`
                    : `&englishProficiencyListening=${englishOtherDetailsObj?.englishProficiencyListening}&englishProficiencyReading=${englishOtherDetailsObj?.englishProficiencyReading}&englishProficiencyWriting=${englishOtherDetailsObj?.englishProficiencyWriting}&englishProficiencySpeaking=${englishOtherDetailsObj?.englishProficiencySpeaking}`
            }`
        );
    }

    getUniversityDetails(id: string): Observable<any> {
        return this._httpClientService.get(
            `${UniversityAPI.getUniversityDetails}/${id}`
        );
    }

    getUniversitiesByCountry(
        country: string | number,
        pagination: { limit: number; skip: number }
    ): Observable<any> {
        return this._httpClientService.get(
            `${UniversityAPI.getBoardName}?${
                country ? 'country=' + country : ''
            }&limit=${pagination?.limit}&skip=${pagination?.skip}`
        );
    }

    setUniversity(university: null) {
        this.setUniversity$.next(university);
        console.log(this.setUniversity$.value);
        if (!university) {
            this._localStorageService.removeKey(StorageConst.university);
        }
    }

    get getSelectedUniversity(): Observable<any> {
        return this.setUniversity$.asObservable();
    }

    setAllApplicationStatus(status: boolean = false) {
        this.isAllApplication$.next(status);
    }

    get getIsAllApplication(): Observable<any> {
        return this.isAllApplication$.asObservable();
    }

    getUniversityAffiliate(
        universityId: string = '',
        coursesType: string = ''
    ): Observable<any> {
        return this._httpClientService.get(
            `${UniversityAPI.getUniversityAffiliate}/${universityId}?coursesType=${coursesType}`
        );
    }

    getUniversityAffiliateOptions(optionId: string = '4'): Observable<any> {
        return this._httpClientService.get(
            `${UniversityAPI.optionsGetById}/${optionId}`
        );
    }
    getUniversityByTenant(): Observable<any> {
        return this._httpClientService.get(
            `${UniversityAPI.getUniversityByTenant}`
        );
    }
}
